<template>
  <Layout :title="data.title">
    <div class="flex flex-col w-full container">
      <div
        v-for="offer in newOffers"
        :key="offer.id"
        class="interior-offer-join-item flex flex-row items-center justify-between w-full pb-2"
      >
        <div>
          <h3>{{ offer.hasUser.name }}</h3>
          <h4>{{ offer.contents }}</h4>
          <h5><span>제안금액</span> {{ offer.price.toLocaleString() }}만원</h5>
        </div>

        <button
          v-if="offer.isConfirm == false"
          @click="() => onSubmit(offer.id)"
        >
          채택하기
        </button>
        <button class="inactive" v-else>
          채택완료
        </button>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/Layout.vue";
import services from "@/services";

export default {
  name: "PlaceOfferJoinList",
  components: {
    Layout,
  },
  data() {
    return {
      data: { title: "" },
      offers: [],
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    newOffers() {
      return this.offers.map((offer) => {
        const newOffer = Object.assign({}, offer);
        newOffer.price = newOffer.price / 10000;
        return newOffer;
      });
    },
  },
  methods: {
    async getData() {
      const { id } = this.$route.params;
      const [data, offers] = await Promise.all([
        services.placeDetail(id),
        services.placeJoinOfferList({ placeId: id }),
      ]);
      this.data = data;
      this.offers = offers;
    },
    async onSubmit(id) {
      const success = await services.placeJoinOfferUpdate(id, {
        isConfirm: true,
      });
      if (success) {
        this.$toast.open("채택완료");
        this.$router.go("-1");
      } else {
        this.$toast.open({ message: "채택실패", type: "error" });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.interior-offer-join-item {
  height: 120px;
  border-bottom: 1px solid #e6e6e6;

  h3 {
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -1.5px;
    text-align: left;
    color: #1a1a1a;
  }

  h4 {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.56px;
    text-align: left;
    color: #737373;
    white-space: pre-line;
  }

  h5 {
    font-size: 18px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -1.5px;
    text-align: left;
    color: #1a1a1a;

    span {
      font-weight: 400;
    }
  }

  button {
    width: 135px;
    height: 46px;
    border-radius: 10px;
    background-color: #283aef;

    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.16px;
    text-align: center;
    color: #fff;

    &.inactive {
      background-color: #c7c7c7;
    }
  }
}

@media (max-width: 639px) {
  .interior-offer-join-item {
    height: auto;
    padding: 15px 0;
    gap: 15px;
    h3 {
      font-size: 14px;
    }
    h4 {
      font-size: 12px;
    }
    h5 {
      font-size: 14px;
    }
    button {
      font-size: 12px;
      height: 35px;
      width: 100px;
    }
  }
}
</style>
